'use strict';

/**
 * Updates availability messages for buybox
 * @param {Object} data - The result of the controller call
 * @param {jQuery} $addToCartButton - The add to cart button
 * @param {jQuery} $availabilityMessage - The availability messages field
 */
function updateAvailability(data, $addToCartButton, $availabilityMessage) {
    var availabilityValue = '';
    var availabilityMessages = data.messages;

    availabilityMessages.forEach(function (message) {
        availabilityValue += '<li><div>' + message + '</div></li>';
    });

    $availabilityMessage.empty().html(availabilityValue);

    $addToCartButton.attr('disabled', !data.success);
}

/**
 * Updates availability for offers when quantity is changed
 */
function offerAvailability() {
    $(document).on('change', '.offer-quantity-select', function (e) {
        e.preventDefault();
        var url = $(this)
            .children('option:selected')
            .data('url');
        var $addToCartButton;
        var $availabilityMessage;

        $addToCartButton = $(this)
            .closest('.offer-details')
            .find('.offer-add-to-cart');
        $availabilityMessage = $(this)
            .closest('.offer-details')
            .find('.offer-availability-msg');

        $.ajax({
            url: url,
            method: 'get',
            success: function (data) {
                updateAvailability(
                    data.result,
                    $addToCartButton,
                    $availabilityMessage
                );
            }
        });
    });
}

module.exports = {
    offerAvailability: offerAvailability
};
